<template>
    <div :id="blok._uid"
        class="col-span-full mx-xs md:mx-md 2xl:mx-0">
        <v-scrollable class="space-y-2 px-2 lg:px-0">
            <div class="flex flex-nowrap space-x-sm"
                :class="{
                    'h-96 md:h-[600px]': isNormal,
                    'h-56 md:h-[330px]': isSquare
                }"
            >
                <v-scrollable-item v-for="(content, index) in blok.content"
                    :key="index"
                    class="h-auto w-60 shrink-0 snap-start md:w-80">
                    <v-storyblok-component :blok="content" />
                </v-scrollable-item>
            </div>
        </v-scrollable>
    </div>
</template>

<script setup lang="ts">
import type { BlokComponent, ProductHighlightBlokContent } from '~~/types';

const properties = defineProps<{
    blok: Extract<BlokComponent, { component: 'sb-product-highlights'
    }>
}>();

// eslint-disable-next-line max-len
const isSquare = computed(() => (
    properties.blok.content.some((component: ProductHighlightBlokContent) => (
        component.component === 'sb-product-highlight-square'
    ))
));
// eslint-disable-next-line max-len
const isNormal = computed(() => (
    properties.blok.content.some((component: ProductHighlightBlokContent) => (
        component.component === 'sb-product-highlight'
    ))
));
</script>
